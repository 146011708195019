<template>
  <div>
    <div class="contact-label">
      <p class="title">お知らせ</p>
      <p class="msg" >{{ msg }}</p>
      <p class="error">{{ errormsg }}</p>
    </div>
    <section id="conntact">
      <form @submit.prevent="SendPost()" action="/news" method="POST">
      <div v-if="id_token" class="iframeForm">
        <dl class="form-field company pd-text required">
            <dt class="field"><label for="subject">タイトル</label></dt>
            <dd class="form">
              <input
                type="text"
                v-model="subject"
                ref="input"
                class="text"
                size="30"
                maxlength="255"
                onchange=""
                onfocus=""
                placeholder="例）タイトル"
              />
            </dd>
          </dl>

          <dl class="form-field inquiry_contents pd-textarea required">
            <dt class="field"><label for="contents">本文</label></dt>

            <dd class="form">
              <textarea
                v-model="contents"
                ref="input"
                onchange=""
                cols="40"
                rows="10"
                class="standard"
              ></textarea>
            </dd>
          </dl>

          <dl>
            <dt class="field"><label for="image">画像</label></dt>
            <dd><ImgUpload v-model="picture" /></dd>
          </dl>

        </div>

        <div v-if="id_token" class="publicBtn">
          <button type="submit">登録 </button>
        </div>
        <div v-if="!id_token" class="info">
        <p class="error">お知らせ情報の登録には、認証が必要です。</p>
        </div>  
      </form>
      </section>
      
      
  </div>
</template>

<script>
import ImgUpload from '../components/ImgUpload.vue'
export default {
  name: 'NewsUpload',
  components: {
    ImgUpload
  },
  data(){
    return{
      msg: '',
      errormsg: '',
      subject: '',
      contents: '',
      picture: '',
      id_token: '',
    }
  },
  methods:{
    SendPost(){

      //console.log(this.subject);
      //console.log(this.contents);
      this.msg = ''
      this.errormsg = ''
      if (this.contents == '' || this.subject == ''){
        this.errormsg = '入力が不足しています'
      }else{
        this.errormsg = ''
        this.$axios.post(this.$API_URL,{ 
            subject: this.subject,
            contents: this.contents,
            image: this.picture
        },
        {
          headers:{
            Authorization: this.id_token
          }
        })
        .then(() => {
          //console.log(response);
          this.msg = '登録が完了しました';
        })
        .catch( () => {
          //console.log(error);
          if (this.id_token == undefined){
            this.errormsg = '登録には再度認証が必要です';       
          }else{
            this.errormsg = '登録に失敗しました';
          }          
        })
        .finally(() =>{
          //pass
        })
      }
    },
    GetToken(){      
      
      let uri = window.location.href.split('#');
      if(uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v) {
          tmp = v.split('=');
          if(tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });
        //console.log(getVars);
        return getVars.id_token
      }
    },
  },
  mounted: function () {
    this.id_token = this.GetToken()
  }
}
</script>

<style scoped>

.contact-label {
  padding-top:90px;
  text-align: center;
}
.title{
  font-size: 1.5em;
}
.msg{
  color:blue;
}
.error{
  color:red;
}

#conntact {
  padding: 5% 20%;
  border-radius: 11px;
}

#conntact .iframeForm {
  padding: 5px 40px 50px;
  background-color: #F3F3F3;
  border-radius: 11px;
}

.iframeForm * {
  box-sizing: border-box;
}

.iframeForm .errors,
.iframeForm .error.no-label {
    color: red;
}

.iframeForm dl {
  margin: 45px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.iframeForm dl dt {
  width: 40%;
  margin: 0;
  padding: 0 76px 0 0;
  border-bottom: none;
  box-sizing: border-box;
}


.iframeForm dl dt label {
  font-family: Arial,sans-serif;
  font-size: 17px;
  font-weight: bold;
  display: block;
}

.iframeForm dl dd {
  width: 60%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.iframeForm dl dd input[type*="text"] {
  width: 100%;
  height: 59px;
  margin: 0;
  padding: 5px;
  background-color: #fff;
  border: none;
  border-radius: 0;
}

.iframeForm dl dd textarea {
  width: 100%;
  height: 150px;
  margin: 0;
  padding: 20px 5px;
  background-color: #fff;
  border: none;
  border-radius: 0;
  line-height: 1.5;
}



.publicBtn {
  margin: 60px;
  text-align: center;
}
.publicBtn button:hover{
  background: #f56500;
}

.publicBtn button {
  width: 120px;
  height: 60px;
  padding: 10px 35px;
  background: #eb6100;
  border: solid 1px #eb6100;
  border-radius: 100vh;
  color: #fff;
  font-family: Arial,sans-serif;
  font-size: 20px;
  white-space: pre-line;
  word-break: break-all;
  cursor: pointer;
  appearance: none;
}

.iframeForm dl dt.field {
  margin: 0;
  width: 40%;
  border-bottom: none;
  box-sizing: border-box;
  padding: 0 76px 0 0;
  display: flex;
  align-items: center;
  position: relative;
}

.iframeForm dl dd.form {
    width: 230%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.iframeForm dl dd.form input[type*="text"] {
    width: 100%;
    height: 59px;
    margin: 0;
    padding: 5px;
    background-color: #fff;
    border: none;
    border-radius: 0;
}

.iframeForm dl dd.form textarea {
    width: 100%;
    height: 150px;
    margin: 0;
    padding: 20px 5px;
    background-color: #fff;
    border: none;
    border-radius: 0;
    line-height: 1.5;
}

.iframeForm #upload {
  margin-top: -60px;
  text-align: left;
}

</style>